var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('avue-crud',{ref:"crud",attrs:{"option":_vm.tableOption,"data":_vm.tableData,"page":_vm.tablePage,"tableLoading":_vm.tableLoading},on:{"size-change":_vm.sizeChange,"current-change":_vm.pageChange,"search-change":_vm.searchChange,"selection-change":_vm.handleSelectionChange},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"menu-btn-list"},[_c('checkAllData',{ref:"checkAllData",attrs:{"tableData":_vm.tableData,"page":_vm.tablePage.pageIndex,"size":_vm.tablePage.pageSize,"total":_vm.tablePage.total,"isPageChange":_vm.isPageChange,"setIsPageChangeFalse":_vm.setIsPageChangeFalse,"getAllData":_vm.getAllData,"selectionData":_vm.selectionData,"selectable":_vm.tableOption.selectable,"getElTableInstance":_vm.getElTableInstance},on:{"checkAllStatus":_vm.checkAllStatusChange}}),_c('sendFactory',{attrs:{"beforeSubmit":_vm.getSelectionDataAllArr,"orderStatus":_vm.orderStatus,"factoryData":_vm.selectionDataAllArr,"sup_this":_vm.sup_this}}),(_vm.orderStatus == 41)?_c('loadingBtn',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.syncKnifeLayout}},[_vm._v("同步刀版图")]):_vm._e()],1)]},proxy:true},{key:"importTimeSearchForm",fn:function(ref){
var form = ref.form;
var prop = ref.prop;
return [_c('SaleDateTime',{attrs:{"time":form[prop]},on:{"update:time":function($event){return _vm.$set(form, prop, $event)},"change":_vm.searchChange}})]}},{key:"isKnifeLayoutCreated",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.viewKnifeLayout(row)}}},[_vm._v(" "+_vm._s(row.isKnifeLayoutCreated === 1 ? '已生成' : '未生成')+" ")])]}},{key:"menu",fn:function(ref){
var row = ref.row;
return [_c('changeStateBtn',{staticClass:"menu-btn-item",attrs:{"data":row,"sup_this":_vm.sup_this}}),_c('el-button',{staticClass:"menu-btn-item",attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.viewDetails(row)}}},[_vm._v("查看详情")]),_c('loadingBtn',{staticClass:"menu-btn-item",attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.syncKnifeLayout('order', row)}}},[_vm._v("同步刀版图")])]}},{key:"orderType",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.orderType === 2 ? 'FBA订单' : '普通订单')+" ")]}},{key:"expressCompanyId",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.expressName(row))+" ")]}},{key:"userName",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.userName)),(row.userStatus === 2)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("(已注销)")]):_vm._e()]}}])}),_c('orderDetail',{attrs:{"visible":_vm.orderDetailDialogVisible,"data":_vm.orderDetailData},on:{"update:visible":function($event){_vm.orderDetailDialogVisible=$event}}}),_c('viewKnifeLayout',{attrs:{"visible":_vm.dialogVisible,"data":_vm.dialogData},on:{"update:visible":function($event){_vm.dialogVisible=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }