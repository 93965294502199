<template>
  <div class="orderDetile">
    <el-dialog title="产品详情" :visible.sync="orderDetailDialogVisible" width="800px">
      <div>
        <div class="border-left-2">收货和物流信息</div>
        <table width="100%" border="1" cellspacing="0" cellpadding="0" class="custom-table table-form mt15">
          <colgroup>
            <col width="18%" />
            <col width="32%" />
            <col width="18%" />
            <col width="32%" />
          </colgroup>
          <tr>
            <td>收货人:</td>
            <td>{{ data.consigneeName }}</td>
            <td>收货人电话:</td>
            <td>{{ data.consigneeContactPhone }}</td>
          </tr>
          <tr>
            <td>收货人手机号码:</td>
            <td>{{ data.consigneeContactMobilePhone }}</td>
            <td>国家:</td>
            <td>{{ data.consigneeCountryCode }}</td>
          </tr>
          <tr>
            <td>州/省:</td>
            <td>{{ data.consigneeProvince }}</td>
            <td>城市:</td>
            <td>{{ data.consigneeCity }}</td>
          </tr>
          <tr>
            <td>物流方式:</td>
            <td>{{ expressName(data) }}</td>
            <td>运单号:</td>
            <td>{{ data.expressWaybillCode }}</td>
          </tr>
          <tr>
            <td>邮编:</td>
            <td>{{ data.consigneePostcode }}</td>
            <td>收货地址:</td>
            <td>{{ data.consigneeDetailAddress }}</td>
          </tr>
        </table>
      </div>
      <div class="mt20">
        <div class="border-left-2">
          <span class="mr15">产品信息</span>
          <span class="mr15">订单号: {{ data.orderCode }}</span>
          <span class="mr15">导入时间: {{ data.importTime }}</span>
        </div>
        <customTable :data="data.orderItemDTOList" :option="orderOption"></customTable>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import customTable from '@/views/components/customTable'
export default {
  components: {
    customTable
  },
  data() {
    return {
      orderDetailDialogVisible: false,
      orderOption: {
        menu: false,
        pageSizes: [4],
        column: [
          {
            label: '商品信息',
            prop: 'productCnName',
            type: 'imgText',
            srcProp: 'productShowCover',
            textProp: 'productCnName',
            width: 180
          },
          {
            label: 'SKU',
            prop: 'productInternalSkuCode',
            formatter(row) {
              return row.orderItemChildren ? row.productCustomSkuCode : row.productInternalSkuCode
            }
          },
          {
            label: '单价',
            prop: 'internalUnitPrice'
          },
          {
            label: '重量',
            prop: 'unitNetWeight'
          },
          {
            label: '款式',
            prop: 'productStructName'
          },
          {
            label: '尺码',
            prop: 'productSizeName'
          },
          {
            label: '数量',
            prop: 'productCount'
          }
        ]
      }
    }
  },
  props: {
    visible: {
      type: Boolean
    },
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  watch: {
    data: {
      handler() {
        this.page = {
          pageIndex: 1,
          pageSize: 4,
          total: this.data.orderItemDTOList.length
        }
      },
      deep: true
    },
    visible: {
      handler(n) {
        this.orderDetailDialogVisible = n
      },
      immediate: true
    },
    orderDetailDialogVisible(n) {
      this.$emit('update:visible', n)
    }
  },
  methods: {
    pageChange(e) {
      this.page.pageIndex = e
    },
    sizeChange(e) {
      this.page.pageIndex = 1
      this.page.pageSize = e
    },
    expressName(scoped) {
      if (scoped.orderType == 3) {
        return this.logistics_service_name(scoped.expressThird)
      }
      return scoped.expressShippingMethodCnName || scoped.expressCompanyName || '暂无'
    },
    logistics_service_name(expressThird) {
      try {
        return JSON.parse(expressThird)?.logistics_service_name
      } catch (error) {
        return '暂无'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.orderDetile {
  display: inline-block;
}

// 订单详情 - 弹窗
.custom-table {
  border-collapse: collapse;
  text-align: center;
  border-color: #ebeef5;

  td {
    padding: 8px;
  }
  tr,
  td {
    border-color: #ebeef5;
  }
}
.table-form {
  td:nth-child(2n-1) {
    background-color: #f7f8fa;
    font-weight: bold;
  }
}

.border-left-2 {
  padding-left: 5px;
  border-left: 2px solid $color-primary;
}
</style>
