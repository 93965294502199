export const tableOption = {
  selection: true,
  selectable(row) {
    return row.cutoffStatus === 1
  },
  editBtn: false,
  delBtn: false,
  menuWidth: 300,
  searchSpan: 6,
  searchLabelWidth: 100,
  column: [
    {
      label: '订单号',
      prop: 'orderCode',
      search: true
    },
    {
      label: '物流',
      prop: 'expressCompanyId',
      search: true,
      slot: true,
      type: 'select'
    },
    {
      label: '运单号',
      prop: 'expressWaybillCode',
      search: true
    },
    {
      label: '用户',
      searchLabel: '分销商',
      prop: 'userName',
      search: true,
      slot:true
    },
    {
      label: '产品名称',
      prop: 'productCnName',
      hide: true,
      search: true
    },
    {
      label: '款式名称',
      prop: 'structName',
      hide: true,
      search: true
    },
    {
      label: '内部SKU',
      prop: 'inSku',
      hide: true,
      search: true
    },
    {
      label: '自定义SKU',
      prop: 'customSku',
      hide: true,
      search: true
    },
    {
      label: '刀版图状态',
      prop: 'isKnifeLayoutCreated',
      slot: true,
      search: true,
      type: 'select'
    },
    {
      label: '导入时间',
      prop: 'importTime',
      hide: true,
      search: true,
      searchFormSlot: true,
      searchWidth: 555,
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd'
    },
    {
      label: '导出时间',
      prop: 'sendTime',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd'
    },
    {
      label: '状态',
      prop: 'orderStatus',
      type: 'select'
    },
    {
      label: '是否已发到工厂',
      prop: 'cutoffStatus',
      search: true,
      searchLabelWidth: 120,
      type: 'select'
    },
    {
      label: '订单类型',
      prop: 'orderSize',
      hide: true,
      search: true,
      type: 'select',
      dicData: [
        {
          label: '全部',
          value: 'all'
        },
        {
          label: '大单',
          value: 1
        },
        {
          label: '小单',
          value: 0
        }
      ]
    },
    {
      label: '订单类别',
      prop: 'orderType',
      slot: true,
      search: true,
      type: 'select'
    }
  ]
}
