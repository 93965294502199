<template>
  <div class="app-container">
    <avue-crud
      ref="crud"
      :option="tableOption"
      :data="tableData"
      :page="tablePage"
      :tableLoading="tableLoading"
      @size-change="sizeChange"
      @current-change="pageChange"
      @search-change="searchChange"
      @selection-change="handleSelectionChange"
    >
      <template #header>
        <div class="menu-btn-list">
          <checkAllData
            ref="checkAllData"
            :tableData="tableData"
            :page="tablePage.pageIndex"
            :size="tablePage.pageSize"
            :total="tablePage.total"
            :isPageChange="isPageChange"
            :setIsPageChangeFalse="setIsPageChangeFalse"
            :getAllData="getAllData"
            :selectionData="selectionData"
            :selectable="tableOption.selectable"
            :getElTableInstance="getElTableInstance"
            @checkAllStatus="checkAllStatusChange"
          ></checkAllData>
          <sendFactory
            :beforeSubmit="getSelectionDataAllArr"
            :orderStatus="orderStatus"
            :factoryData="selectionDataAllArr"
            :sup_this="sup_this"
          ></sendFactory>
          <loadingBtn type="primary" size="small" @click="syncKnifeLayout" v-if="orderStatus == 41"
            >同步刀版图</loadingBtn
          >
        </div>
      </template>
      <template #importTimeSearchForm="{ form, prop }">
        <SaleDateTime :time.sync="form[prop]" @change="searchChange"></SaleDateTime>
      </template>
      <template #isKnifeLayoutCreated="{ row }">
        <el-button type="text" @click="viewKnifeLayout(row)">
          {{ row.isKnifeLayoutCreated === 1 ? '已生成' : '未生成' }}
        </el-button>
      </template>
      <template #menu="{ row }">
        <changeStateBtn class="menu-btn-item" :data="row" :sup_this="sup_this"></changeStateBtn>
        <el-button class="menu-btn-item" type="text" size="small" @click="viewDetails(row)">查看详情</el-button>
        <loadingBtn class="menu-btn-item" type="text" size="small" @click="syncKnifeLayout('order', row)"
          >同步刀版图</loadingBtn
        >
      </template>

      <template #orderType="{ row }">
        {{ row.orderType === 2 ? 'FBA订单' : '普通订单' }}
      </template>
      <!-- 物流 -->
      <template #expressCompanyId="{ row }">
        {{ expressName(row) }}
      </template>
      <template #userName="{ row }">
        {{ row.userName }}<span v-if="row.userStatus === 2" style="color:red">(已注销)</span>
      </template>
    </avue-crud>

    <orderDetail :visible.sync="orderDetailDialogVisible" :data="orderDetailData"></orderDetail>

    <viewKnifeLayout :visible.sync="dialogVisible" :data="dialogData"></viewKnifeLayout>
  </div>
</template>

<script>
import avueCrud from '@/mixins/avueCrud'
import { tableOption } from './const'
import changeStateBtn from '@/views/order/module/orderListModule/changeStateBtn'
import orderDetail from '@/views/order/orderDetail'
import sendFactory from '@/views/order/module/orderListModule/sendFactory'
import SaleDateTime from '@/components/saleDateTime'
import checkAllData from '@/views/components/checkAllData3'
import viewKnifeLayout from './viewKnifeLayout'
import { getValueFromObj } from '@/utils'
import { orderList as getList, syncKnifeLayoutPath } from '@/api/order'
import { validatenull } from '@/components/avue/utils/validate'

export default {
  components: {
    changeStateBtn,
    orderDetail,
    sendFactory,
    SaleDateTime,
    checkAllData,
    viewKnifeLayout
  },
  mixins: [
    avueCrud({
      tableOption,
      getList
    })
  ],
  data() {
    return {
      sup_this: this,
      resetMergeData: {
        cutoffBatchId: this.$route.query.sendBatchId,
        isReturnRelatedData: 1 // 是否返回订单orderItemDTOList：0不返回，1返回
      },
      orderDetailDialogVisible: false,
      orderDetailData: {},

      dialogVisible: false,
      dialogData: null,

      orderStatus: '41',
      // 全选必加
      // selectionData: [],
      selectionDataAllArr: []
    }
  },
  methods: {
    viewDetails(row) {
      this.orderDetailData = row
      this.orderDetailDialogVisible = true
    },
    viewKnifeLayout(row) {
      this.dialogVisible = true
      this.dialogData = row
    },
    expressName(scoped) {
      if (scoped.orderType == 3) {
        return this.logistics_service_name(scoped.expressThird)
      }
      return scoped.expressCompanyName
    },
    logistics_service_name(expressThird) {
      try {
        return JSON.parse(expressThird)?.logistics_service_name
      } catch (error) {
        return '暂无'
      }
    },
    async syncKnifeLayout(type, row) {
      switch (type) {
        case 'order':
          row = Array.isArray(row) ? row : [row]
          break
        default:
          row = await this.getSelectionDataAllArr()
          if (!row) {
            return this.$message.warning('请先选择订单')
          }
          break
      }
      let res = await awaitResolve(
        syncKnifeLayoutPath({
          orderIdList: row.map((item) => item.id)
        })
      )
      if (!res) return this.$message.warning('同步刀版图失败')
      this.$message.success('同步刀版图成功')
      await this.refreshPage()
    },

    // 全选必加
    afterInit(res, postData) {
      let initCallBack = getValueFromObj(this.$refs.checkAllData, 'initCallBack')
      if (initCallBack) initCallBack(res?.detail || [], postData.page.pageIndex)
    },
    checkAllStatusChange(status) {
      if (['indeterminate', 'all'].includes(status)) {
        this.selectionDataAllArr = [true]
      } else {
        this.selectionDataAllArr = []
      }
      // console.log(this.selectionDataAllArr)
    },
    async getSelectionDataAllArr() {
      let tempArr = await this.$refs.checkAllData.getSelectionDataAllArr()
      if (validatenull(tempArr)) return
      this.selectionDataAllArr = tempArr.map(({ id }) => id)
      // console.log(tempArr)
      return tempArr
    },
    async getAllData() {
      let res = await awaitResolve(
        getList({
          ...this.postData,
          isReturnRelatedData: 0,
          isReturnWaybillChangeRecord: 0,
          page: {
            pageIndex: 1,
            pageSize: 0
          }
        })
      )
      res = res?.detail
      if (!res) this.$message.warning('获取数据失败，请重试')
      return res
    },
    getElTableInstance() {
      return getValueFromObj(this.$refs, 'crud.$refs.table')
    }
  }
}
</script>

<style lang="scss"></style>
